<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 md12>
      <v-card class="mb-3">
        <titleCard title="Diseñadores" subtitle="Diseñadores que participaron en la elaboracion"> </titleCard>
        <v-divider light></v-divider>
        <v-card-text>
          <v-data-table
            color="primary"
            :items="order.products"
            :headers="[
              { text: 'Producto', align: 'left', sortable: false },
              { text: 'Fecha elaboracion', align: 'left', sortable: false },
              { text: 'Disenador', align: 'left', sortable: false },
              { text: 'Tiempo', align: 'left', sortable: false }
            ]"
            class="elevation-0 primary"
            hide-actions
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.name }}</td>
              <td>
                {{ $moment.unix(props.item.confeccion.date_out).format('DD-MM-YYYY HH:mm:ss') }}
              </td>
              <td>
                <span v-if="_.size(props.item.confeccion) > 0">{{ props.item.confeccion.name }}</span>
                <span v-else-if="props.item.elaboracion === 1">
                  <v-select v-model="props.item.confeccionID" item-text="name" item-value="id" :items="disenadores">
                  </v-select>
                </span>
                <span v-else>
                  <v-chip class="white--text ma-0" color="error" label>No requiere elaboración</v-chip>
                </span>
              </td>
              <td>
                <template v-if="props.item.confeccion.status === 0">
                  <v-chip color="warning" text-color="white">
                    <v-avatar>
                      <v-icon>timelapse</v-icon>
                    </v-avatar>
                    <TimeGo :number="props.item.confeccion.seconds"></TimeGo>
                  </v-chip>
                  <v-btn color="success" depressed small @click.stop="updateDesigner(1, props.index)">
                    Confeccionado
                  </v-btn>
                </template>
                <v-chip v-else-if="props.item.confeccion.status === 1" color="success" text-color="white">
                  <v-avatar>
                    <v-icon>check_circle</v-icon>
                  </v-avatar>
                  Confeccionado
                </v-chip>
                <span v-else-if="props.item.elaboracion === 1">
                  <v-btn color="info" flat small @click.stop="updateDesigner(0, props.index)">
                    Asignar
                  </v-btn>
                </span>
                <span v-else>
                  -
                </span>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import titleCard from '../useful/titleCard.vue'
import TimeGo from '../useful/timeGo.vue'
import { ORDER } from '../../config'

export default {
  name: 'OrderDesign',
  components: { titleCard, TimeGo },
  props: ['order', 'getOrder'],
  computed: {
    disenadores() {
      return this.$store.getters.getDisenadores
    }
  },
  mounted() {
    this.$store.dispatch('updateTitle', `Diseñadores - Pedido ${this.order.id}`)
  },
  methods: {
    async updateDesigner(type, index) {
      try {
        const disenadorID = !type ? this.order.products[index].confeccionID : this.order.products[index].confeccion.id
        await this.$http.post(`${ORDER}/${this.order.id}/designer`, {
          type,
          detailID: this.order.products[index].detalle_id,
          productID: this.order.products[index].id,
          disenadorID
        })
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    }
  }
}
</script>

<style></style>
